<template>
  <div class="confimOrder-div__container">
    <div class="confimOrder-div">
      <div class="confimOrder-div__title">
        <div class="div__countDOwn">
          <img v-if="!isCancel" :src="urlTrue" alt />
          <i18n-t v-if="!isCancel" keypath="subscribe.count_down" tag="span">
            <template #hour>
              <span class="countDown">0</span>
            </template>
            <template #min>
              <span class="countDown">{{minutes}}</span>
            </template>
            <template #sec>
              <span class="countDown">{{seconds}}</span>
            </template>
          </i18n-t>
          <span v-if="isCancel">{{copyWriter}}</span>
        </div>
        <div class="order div__countDOwn">
          <span>{{$tLabel($t('subscribe.order_num'))}}{{store_order_id}}</span>
        </div>
        <div v-if="!isCancel" class="confimOrder-div__confim money">
          <span>{{$tLabel($t('subscribe.amounts_payable'))}}</span>
          <span>{{ $isZh() ? '¥' : '$' }}{{formatNum(store_rec_amount)}}</span>
        </div>
        <div class="confimOrder-div__confim">
          <el-button
            v-if="!isCancel"
            :class="{isDisabled:'isDisabled'}"
            class="uploadConfig-btn"
            type="primary"
            @click="confimPay"
          >{{$t('subscribe.confirm_payment')}}</el-button>
          <el-button
            v-else
            :class="{isDisabled:'isDisabled'}"
            class="uploadConfig-btn"
            type="primary"
            @click="orderRecord"
          >{{$t('subscribe.check_order')}}</el-button>
        </div>
      </div>
    </div>
    <orderDialog v-if="centerDialogVisible" @closeDialog='closeDialog' :startTime='store_startTime'></orderDialog>
  </div>
</template>

<script setup>
// import { mapGetters, mapMutations } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { ref, computed, onBeforeMount } from 'vue'
import { getPaymentOrderStatus, getPaymentPay } from '@/services/order'
import { message } from '@/hooks/useUI'
import orderDialog from '@/components/order/dialog1'
import urlTrue from '@/assets/img/detection/urlTrue.svg'
import { t } from 'app/i18n'

const route = useRoute()
const router = useRouter()
const Map = {
  'payment-failed': t('subscribe.payment_failed'),
  cancel: t('subscribe.payment_cancel'),
  paid: t('subscribe.payment_paid'),
  unsubscribe: t('subscribe.payment_unsubscribe'),
  'pay-timeout': t('subscribe.payment_timeout'),
  'unsubscribed-successfully': t('subscribe.unsubscribed')
}
const waitTime = 30 * 60 * 1000

const startTime = ref('')
const minutes = ref(0)
const seconds = ref(0)
const isCancel = ref(true)
const copyWriter = ref('')
const timeDown = ref(0)
const centerDialogVisible = ref(false)
const timeout = ref(null)
// const order_type = ref('')

const store_startTime = computed(() => {
  return route.query.startTime
})

const store_order_id = computed(() => {
  return route.query.order_id
})

const store_rec_amount = computed(() => {
  return route.query.rec_amount
})

const store_order_type = computed(() => {
  return route.query.order_type
})

onBeforeMount(() => {
  getOrderStatus()
})

const getOrderStatus = () => {
  getPaymentOrderStatus(route.query).then(res => {
    // this.setOrderInfo({
    //   startTime: res.create_time
    // })
    if (res.status === 1) {
      copyWriter.value = t('subscribe.order_err')
      return
    }
    if (res.order_status !== 'non-payment') {
      isCancel.value = true
      copyWriter.value = Map[res.order_status]
    } else {
      isCancel.value = false
      getTime()
    }
  })
}

const confimPay = () => {
  const query = {
    order_id: store_order_id.value,
    order_type: store_order_type.value
  }
  getPaymentPay(query).then(res => {
    if (!res.status) {
      centerDialogVisible.value = true
      window.open(`${res.url}`)
    } else {
      message(res.msg, 'error')
      timeout.value && clearTimeout(timeout.value)
      getOrderStatus()
    }
  }).catch(e => {
    console.log(e)
  })
}
const orderRecord = () => {
  centerDialogVisible.value = false
  router.push({
    name: 'order'
  })
}
const closeDialog = (flag) => {
  centerDialogVisible.value = false
  if (!flag) return
  router.push({
    name: 'order'
  })
}
// const goHelp = () => {
//   window.open('https://mall.360.cn/help/show?id=helpcontent_5cee349b1e43e02edaf7000e7c48133c')
// }
const setCountDown = () => {
  minutes.value = Math.abs(Math.floor(timeDown.value / 60) + 1)
  seconds.value = Math.floor(Math.abs(timeDown.value) - minutes.value * 60)
  timeout.value = setTimeout(() => {
    getTime()
  }, 500)
}
const getTime = () => {
  startTime.value = new Date(route.query.startTime).getTime()
  const endTime = startTime.value + waitTime
  const now = new Date().getTime()
  timeDown.value = (now - endTime) / 1000
  if (timeDown.value < 0) {
    isCancel.value = false
    setCountDown()
  } else {
    isCancel.value = true
    copyWriter.value = t('subscribe.payment_timeout')
  }
}
const formatNum = (s, n = 0) => {
  if (!s) {
    return '-'
  }
  /*
   * 参数说明：
   * s：要格式化的数字
   * n：保留几位小数
   * */
  n = n > 0 && n <= 20 ? n : 2
  s = parseFloat((s + '').replace(/[^\d.-]/g, '')).toFixed(n) + ''
  var l = s
    .split('.')[0]
    .split('')
    .reverse()
  // var r = s.split('.')[1]
  let t = ''
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? ',' : '')
  }
  return t
    .split('')
    .reverse()
    .join('')
}
</script>

<style lang="scss" scoped>
.confimOrder-div__container {
  width: 100%;
  // height: 100%;
  min-height: calc(100vh - 116px);
  display: flex;
  justify-content: center;
  align-content: center;
  :deep(.payResult) {
    // background-color: #21274a;
    .el-dialog__header {
      text-align: left;
      // background: #242B52;
      // box-shadow: 0 1px 0 0 #2A315D;
      border-radius: 2px 2px 0 0;

      .el-dialog__title {
        font-size: 14px;
        color: var(--color-text-1);
      }
    }

    .el-dialog__body {
      font-size: 14px;
      color: var(--color-text-2);
      padding: 36px 34px 36px 34px;
      // border-bottom: 1px solid #2A315D;

      .countDown {
        font-size: 14px;
      }
    }

    .el-dialog__footer {
      padding: 12px 20px 12px 20px;

      .el-button {
        &:first-child {
          background: #00AB7A;
          color: var(--color-text-1);
        }

        &:last-child {
          border: 1px solid #00AB7A;
          background: none;
          font-size: 14px;
          color: #00AB7A;
        }
      }
    }
  }

  .countDown {
    font-size: 20px;
    color: #cf605b;
    font-family: Verdana-Bold;
  }

  .confimOrder-div {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
  }

  .confimOrder-div__title {
    width: 1000px;
    font-size: 20px;
    color: var( --color-unkown-text);
    margin: auto;

    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  .confimOrder-div__title {
    margin-top: 20%;

    .confimOrder-div__confim {
      display: flex;
      justify-content: flex-end;
      .uploadConfig-btn {
        // width: 120px;
        height: 40px;
        font-size: 16px;
      }
    }

    .money {
      margin-bottom: 20px;
      font-size: 14px;
      color: #ABB1C4;
      display: flex;
      // justify-content center;
      align-items: center;

      span {
        &:last-child {
          font-size: 30px;
          color: #00AB7A;
        }
      }
    }

    .div__countDOwn {
      display: flex;
      margin-bottom: 20px;
    }

    .order {
      border-bottom: 1px solid var(--color-border-2);
      font-size: 16px;
      color: #ABB1C4;
      padding-bottom: 30px;
    }
  }
}

[data-theme='light'] {
  .confimOrder-div__container {
    :deep(.payResult) {
      .el-dialog__footer  {
        .el-button  {
          &:first-child  {
            // background: #537EF3;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
